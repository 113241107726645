<template>
  <div class="rule-drawer-component">
    <el-drawer
      title="关联规则"
      :size="900"
      :visible.sync="drawerShow"
      :before-close="handleCloseDrawer"
    >
      <div class="table-container">
        <finalTable
          ref="finalTableRef"
          :datas="dataset"
          @tableEventHandler="tableEventHandler"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  findRulePage,
  updateRuleStatus,
  findRuleRelation,
} from "@/api/ruge/vlink/rule/rule";
export default {
  name: "ruleDrawerComponent",
  components: {
    finalTable,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    datas: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      listQuery: {
        current: 1,
        rowCount: 10,
      },
      tableLoading: false,
      drawerShow: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "ruleName",
            label: this.$t("vlink.rule.ruleName"),
            width: "",
          },
          { prop: "ruleKey", label: this.$t("vlink.rule.ruleKey"), width: "" },
          {
            prop: "ruleScope",
            label: this.$t("vlink.rule.ruleScope"),
            width: "110",
          },
          {
            prop: "ruleType",
            label: this.$t("vlink.rule.ruleType"),
            width: "110",
          },
          {
            prop: "ruleStatus",
            label: this.$t("vlink.rule.ruleStatus"),
            width: "110",
          },
          {
            prop: "creationDate",
            label: this.$t("commons.creationTime"),
            width: "220",
          },
          { prop: "operation", label: "操作", width: "65" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          ruleName: {
            type: "input",
            label: "规则名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则名称",
            prefixIcon: "el-icon-search",
          },
          ruleKey: {
            type: "input",
            label: "规则标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则标识码",
            prefixIcon: "el-icon-search",
          },
          ruleScope: {
            type: "selector",
            label: "作用域",
            value: "",
            placeholder: "请选择作用域",
            actionType: "goSearch",
            optionList: [
              {
                label: "云端",
                value: "CLOUD",
              },
              {
                label: "边缘",
                value: "EDGE",
              },
            ],
          },
          ruleType: {
            type: "selector",
            label: "规则类型",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设规则类型",
            optionList: [
              {
                label: "联动规则",
                value: "linkage",
              },
              {
                label: "告警规则",
                value: "alarm",
              },
              {
                label: "时间表",
                value: "schedule",
              },
              {
                label: "数据流转",
                value: "serverSub",
              },
              {
                label: "设备通信",
                value: "deviceCall",
              },
            ],
          },
          ruleStatus: {
            type: "selector",
            label: "规则状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设规则状态",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          // operation: {
          //   type: "button",
          //   filterCount: "",
          //   actionType: "clickEvent",
          //   eventName: "showMoreFilters",
          //   label: "更多筛选",
          // },
        },
        // 表格内容配置
        detailConfig: {
          ruleScope: {
            type: "enumerationColumn",
            emuList: {
              CLOUD: "云端",
              EDGE: "边缘",
            },
          },
          ruleType: {
            type: "enumerationColumn",
            emuList: {
              linkage: "联动规则",
              alarm: "告警规则",
              schedule: "时间表",
              serverSub: "数据流转",
              deviceCall: "设备通信",
            },
          },
          ruleStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    tableLoading: {
      handler(val) {
        this.$refs.finalTableRef && this.$refs.finalTableRef.loadingToggle(val);
      },
      deep: true,
    },
    show: {
      handler(val) {
        this.drawerShow = val;
      },
      immediate: true,
    },
    datas: {
      handler(val) {
        console.log("datas", val);
        const { productKey, deviceName } = val;
        this.listQuery.productKey = productKey;
        this.listQuery.deviceName = deviceName;
        this.freshDatas();
      },
      deep: true,
    },
  },
  methods: {
    handleCloseDrawer() {
      this.drawerShow = false;
      this.$emit("close");
    },
    freshDatas() {
      let param = { ...this.listQuery };
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.dataset.tableData = [];
      this.tableLoading = true;
      // if (this.type === "device") {
      //   findRuleRelation(param)
      //     .then((res) => {
      //       this.dataset.pageVO.total = res.total;
      //       this.dataset.tableData = res.rows || [];
      //     })
      //     .finally(() => {
      //       this.tableLoading = false;
      //     });
      //   return;
      // }
      findRulePage(param)
        .then((res) => {
          this.dataset.pageVO.total = res.total;
          this.dataset.tableData = res.rows || [];
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    updateRuleStatus(row) {
      let param = {
        ruleId: row.ruleId,
        ruleStatus: row.ruleStatus,
        ruleType: row.ruleType,
      };
      updateRuleStatus(param)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.freshDatas();
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
        this.freshDatas();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.freshDatas();
      } else if (datas.type === "switchEvent") {
        this.updateRuleStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.jumpToDetail(datas.row);
            break;
        }
      }
    },
    jumpToDetail(row) {
      const { origin, pathname } = window.location;
      const targetUrl = `${origin}${pathname}#/iot/rule/detail?ruleKey=${row.ruleKey}&type=edit`;
      window.open(targetUrl, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.rule-drawer-component {
  .table-container {
    padding: 0 20px;
  }
}
</style>