var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-drawer-component" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "关联规则",
            size: 900,
            visible: _vm.drawerShow,
            "before-close": _vm.handleCloseDrawer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "table-container" },
            [
              _c("finalTable", {
                ref: "finalTableRef",
                attrs: { datas: _vm.dataset },
                on: { tableEventHandler: _vm.tableEventHandler },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }